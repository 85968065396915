import { Link, NavLink } from '@remix-run/react';
import { type FC, type PropsWithChildren, createContext } from 'react';

export enum LinkFrameworks {
  Remix = 'remix',
  None = 'none',
}

export type LinkContextValue =
  | {
      framework: LinkFrameworks.None;
      Link: null;
      NavLink: null;
    }
  | {
      framework: LinkFrameworks.Remix;
      Link: typeof Link;
      NavLink: typeof NavLink;
    };

export const LinkContext = createContext<LinkContextValue>({
  framework: LinkFrameworks.None,
  Link: null,
  NavLink: null,
});

const RemixLinkContextValue: LinkContextValue = {
  framework: LinkFrameworks.Remix,
  Link: Link,
  NavLink: NavLink,
};

export const LinkProvider: FC<PropsWithChildren> = (props) => (
  <LinkContext.Provider value={RemixLinkContextValue} {...props} />
);
